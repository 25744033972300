import { captureException, captureMessage } from "@sentry/browser";

/**
 * Logs an error to the console and to Sentry, if the environment
 * is production.
 * @param {string|object|Error} error The error to log
 */

const isProduction =
  process.env.NODE_ENV === "production" &&
  typeof window !== "undefined" &&
  window.location.host.indexOf("netlify") === -1; // Exclude Netlify. Preview branches have the NODE_ENV set to production.

export const logError = (error) => {
  // Make the error into an Error
  if (!(error instanceof Error)) {
    error = new Error(error);
  }

  if (isProduction) {
    captureException(error);
  }

  console.error("❌ ", error);
};

export const logWarning = (warning) => {
  if (isProduction) {
    captureMessage(warning);
  }

  console.error("⚠️ ", warning);
};
